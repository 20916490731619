import { render, staticRenderFns } from "./GoodsType.vue?vue&type=template&id=4e168cae&scoped=true&"
import script from "./GoodsType.vue?vue&type=script&lang=js&"
export * from "./GoodsType.vue?vue&type=script&lang=js&"
import style0 from "./GoodsType.vue?vue&type=style&index=0&id=4e168cae&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e168cae",
  null
  
)

export default component.exports